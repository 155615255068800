.terms-form {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-family: MerkurLight;
}

.sub-checkbox-container {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

label {
  margin-left: 5px;
}

.terms-submit {
  right: 0;
  top: 50%;
  cursor: pointer;
  height: 45px;
  min-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.terms-submit-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.terms-submit-container button {
  outline: none;
  background-color: #ffa41c;
  border-radius: 10px;
  color: white;
  border: none;
  margin-top: 30px;
  margin-bottom: 2px;
  font-size: 14px;
  text-transform: uppercase;
  font-family: 'MerkurRegular';
}

.back-button {
  background-color: white !important;
  color: #ffa41c !important;
  border: 1px solid #ffa41c !important;
  margin-right: 10px;
}

.submit-button-description-wrapper {
  display: flex;
  justify-content: flex-end;
}

#submit-button-description-text {
  font-size: 10px;
  text-transform: uppercase;
  font-family: 'MerkurRegular';
  display: flex;
  gap: 3px;
}

.terms-submit-container button:disabled {
  opacity: 0.5;
}

.terms-submit-container button:not(:disabled):hover {
  opacity: 0.8;
}

.terms-submit-container-mobile {
  justify-content: flex-end;
}

.checkbox {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  margin-left: 0;
}

.checkbox > span {
  color: black;
  padding: 0.5rem 0.25rem;
}

.checkbox > input {
  height: 20px;
  width: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0.5px solid gray;
  border-radius: 4px;
  outline: none;
  transition-duration: 0.3s;
  background-color: white;
  cursor: pointer;
  margin-left: 0;
}

.checkbox > input:checked {
  border: none;
  background-color: #ffa41c;
}

.checkbox > input:checked + span::before {
  content: '';
  position: absolute;
  left: 7px;
  top: 6px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  opacity: 1;
}

.checkbox > input:active {
  border: 2px solid #34495e;
}

.checkbox-label {
  font-size: 12px;
  margin: 0;
}

.checkbox-inner-item {
  display: flex;
}
