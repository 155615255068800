.errors {
    min-height: 100vh;
    position: relative;
    background-image: url('/src/assets/images/background.jpg');
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
    background-attachment: fixed;
    overflow-x: hidden;
}

.errors-modal-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    @media screen and (max-width: 592px) {
      align-items: center;
    }
}
.errors-modal {
    background-color: white;
    max-width: 550px;
    padding: 60px 30px 30px 30px;
    border-radius: 20px;
    font-family: MerkurLight;
    line-height: 1.2;
    font-size: 14px;
  
    @media screen and (max-width: 592px) {
      width: 75%;
    }
}

button {
    outline: none;
    background-color: #ffa41c !important;
    border-radius: 10px;
    color: white;
    border: none;
    margin-top: 30px;
    margin-bottom: 2px;
    font-size: 14px;
    text-transform: uppercase;
    font-family: 'MerkurRegular';
}

@media screen and (max-width: 592px) {
  .errors-modal-wrapper {
    align-items: center;
  }

  .errors-modal {
    width: 75%;
  }
}