body {
  margin: 0 !important;
}

@font-face {
  font-family: 'MerkurLight';
  src:url('./fonts/Merkur/MerkurSans-Light-web.ttf') format('truetype');
}

@font-face {
  font-family: 'MerkurBold';
  src: url('./fonts/Merkur/MerkurSans-Bold-web.ttf') format('truetype');
}

@font-face {
  font-family: 'MerkurRegular';
  src: url('./fonts/Merkur/MerkurSans-Regular-web.ttf') format('truetype');
}
