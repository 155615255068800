.terms {
  min-height: 100vh;
  position: relative;
  background-image: url('/src/assets/images/background.jpg');
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
  background-attachment: fixed;
  overflow-x: hidden;
}

.terms-modal h1 {
  font-family: MerkurRegular;
  text-transform: uppercase;
}

.terms-description {
  font-family: MerkurLight;
  line-height: 1.2;
  font-size: 14px;
}

.terms-modal-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  @media screen and (max-width: 592px) {
    align-items: center;
  }
}

.agb-description-link {
  text-decoration: underline;
  cursor: pointer;
  color: #ffa41c;
}

.terms-modal {
  background-color: white;
  max-width: 550px;
  padding: 30px;
  border-radius: 20px;

  @media screen and (max-width: 592px) {
    width: 75%;
  }
}

.links {
  margin-top: 5px;
  color: white;
  font-size: 13px;
  font-family: MerkurRegular;
  padding-left: 30px;
  align-self: flex-start;
  display: none;
}

.terms-fields {
  display: grid;
  flex-direction: column;
  font-family: MerkurLight;
  grid-template-columns: repeat(2, 1fr);  
}

.terms-fields p {
  margin: 0;
  font-size: 14px;
}

.links-modal {
  background-color: white;
  max-width: 550px;
  width: 550px;
  padding: 0 30px;
  border-bottom: 30px white;
  border-style: none none dashed none;
  border-radius: 0 0 20px 20px;
  height: 100%;
  max-height: 570px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 50px;
  @media screen and (max-width: 592px) {
    width: 75%;
  }
}

@media screen and (max-width: 592px) {
  .terms-modal {
    width: 75%;
  }
  .links-modal {
    width: 75%;;
  }
  .popup-close-btn {
    width: 75% !important;
  }
  .terms-modal-wrapper {
    align-items: center;
  }
  .popup-links {
    padding: 0 !important;
    margin: 0 10px;
  }
}

.popup-links>*:not(:last-child)::after {
    content: '|';
    margin: 0 5px;
}

.popup-links {
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  color: white;
  padding-left: 30px;
  font-size: 13px;
  font-family: MerkurRegular;

  @media screen and (max-width: 592px) {
    padding: 0;
    margin: 0 10px;
  }
}

.popup-text {
  margin-top: 20px;
  font-family: MerkurLight;
  font-size: 14px;
  line-height: 1.2;
}

.popup-close-btn{
  display: flex;
  flex-direction: row-reverse;
  max-width: 550px;
  width: 550px;
  height: 60px;
  background-color: white;
  padding: 0 30px;
  border-radius: 20px 20px 0 0;
  @media screen and (max-width: 592px) {
    width: 75%;
  }
}
.close-btn {
  width: 20px;
  height: 20px;
  margin-top: 20px;
  cursor: pointer;
}

.popup-imprint {
  font-family: MerkurLight;
  line-height: 1.2;
  font-size: 14px;

  & p {
    margin-top: 0;
  }
}
.highlighted {
  color: #365F91;
  font-family: MerkurBold;
}
.highlighted-title {
  color: #365F91;
  font-family: MerkurBold;
  font-size: 18px;
}
.highlighted-subtitle {
  margin-bottom: 0;
  color: #365F91;
  font-family: MerkurBold;
  font-size: 16px;
}
.agb-data-privacy-link {
  cursor: pointer;
  color: #ffa41c;
}
a{
  color: #ffa41c;
}

.terms-modal .terms-modal-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.popup-multilog-logo {
  background-image: url('/src/assets/images/multilog.png');
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  height: 40px;
  width: 100%;
  margin: 10px 0;
  margin-left: -5px;
}

.multilog-logo {
  background-image: url('/src/assets/images/multilog.png');
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  height: 60px;
  width: 100%;
  margin-left: -5px;
}

.validation-message {
  font-size: 20px;
  color: #fff;
  font-family: 'MerkurBold';
  src:url('../../../fonts/Merkur/MerkurSans-Bold-web.ttf') format('truetype');
}

.validation-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
